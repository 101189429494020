import Vue from "vue";
import antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";
import App from "./App";
import router from "./router";
import { VueAxios } from "./utils/request";
import { ipc } from "@/utils/ipcRenderer";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import store from "./store/index";

// 引入 Vconsole
import Vconsole from "vconsole";
// 所有环境均使用
// new Vconsole()

// 使用antd
Vue.use(antd);
Vue.use(ElementUI);

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios);

import "@/utils/rem.js";

// 全局注入IPC通信
Vue.prototype.$ipc = ipc;
Vue.prototype.$video = videojs;
Vue.prototype.$onlyId = "adh8xsasd5P2rHkwCft2x2"; // 知识库唯一值
Vue.prototype.$baseIP = "106.54.38.16"; // 服务器ip
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
